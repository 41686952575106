<template>
  <multiselect
    placeholder=""
    :showLabels="false"
    v-model="value"
    :options="availableOptions"
    :multiple="true"
    :closeText="$t('search-tab.choose-count-select')"
    track-by="id"
    :custom-label="customLabel"
    :closeOnSelect="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    @close=selecting
    @open=deselecting
    ref="multiselect"
    >
    <template slot="selection" slot-scope="{ values }">
      <span class="multiselect__single" v-if="values.length === 1 && values[0].id === 0">
        <i class="fa fa-hotel" aria-hidden="true"></i> {{$t("search-tab.all-hotels")}} {{ destination }} {{$t("search-tab.or-select")}}
      </span>
      <span class="multiselect__single" v-else>
        <i class="fa fa-hotel" aria-hidden="true"></i>
        <span v-if="values.length === 1"> {{ values[0].label }} </span>
        <span v-else>{{ values.length }} {{$t("search-tab.hotels-selected")}}</span>
      </span>
    </template>

    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
      {{ scope.option.label }}
      <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
    </span>
  </multiselect>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      search: '',
      value: [],
      allItem: {
        id: 0,
        value: 0,
        name: 'all',
        label: this.$t('search-tab.all-hotels'),
        checked: true,
      },
      query: this.$route.query,
    };
  },
  components: {
    Multiselect: () => import('@/components/atoms/multiSelect/Multiselect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    list: {
      type: Array,
      default: () => [],
    },
    hotelListShow: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    availableOptions() {
      const { lang } = this;
      const optionList = this.list.filter((item) => item.active && item.hotelName !== null).map((item, inx) => ({
        id: inx + 1,
        value: item.id,
        code: item.hotelExternalCode || item.hotelCamingoCode,
        camingoCode: item.hotelCamingoCode,
        label: (lang === 'he' ? item.nameTranslations[lang] : item.nameTranslations.en) || item.hotelName,
        checked: false,
        source: item.source ? item.source - 1 : 4,
      }));

      optionList.unshift(this.allItem);
      return optionList;
    },
    stateSelected: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.hotels || [];
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['hotels', value]);
      },
    },
  },
  watch: {
    value() {
      if (this.value.length === 0) {
        this.value.push(this.allItem);
        this.availableOptions[0].checked = true;
      }
      this.$emit('changeHotel', this.value);
      // this.stateSelected = this.value;
    },
    list() {
      this.value = [this.allItem];
      this.availableOptions[0].checked = true;
    },
    stateSelected() {
      this.value = this.stateSelected;
    },
    hotelListShow() {
      this.$refs.multiselect.toggle();
    },
    availableOptions() {
      this.selectInitialValue();
    },
  },
  mounted() {
    this.selectInitialValue();
  },
  methods: {
    selectInitialValue() {
      const qs = (this.query.hotelCode) ? this.query.hotelCode.split(',') : [];
      const qList = this.availableOptions.filter((elm) => qs.findIndex((el) => String(elm.code) === String(el)) > -1);

      if (qList.length > 0) {
        this.value = qList;
        qList.forEach((elm) => this.onSelect(elm));
      } else {
        this.value = [this.allItem];
        this.availableOptions[0].checked = true;
      }
    },
    customLabel(option) {
      return `${option.label}`;
    },
    onSelect(option) {
      if (option.id !== 0) {
        this.availableOptions[0].checked = false;
        if (this.value.findIndex((item) => item.id === 0) > -1) this.value.shift();
      }
      if (option.id === 0) {
        this.availableOptions.forEach((element) => {
          element.checked = false;
        });
        setTimeout(() => { this.value = []; }, 50);
      }
      this.availableOptions[option.id].checked = true;
    },
    onRemove(option) {
      if (option.id !== 0) {
        this.availableOptions[option.id].checked = false;
      }
    },
    select() {
    },
    selecting() {
      this.$emit('optionSelect', true);
    },
    deselecting() {
      this.$emit('optionSelect', false);
    },
  },
};
</script>
<style>
.filter-Wrap1 .multiselect__select{
  top: 7px;
}
.filter-Wrap1 .multiselect__strong{
  margin: 0;
}
.filter-Wrap1 .multiselect__tags, .multiselect__select{
  height: 50px;
  border-radius: 30px;
}
.filter-Wrap1 .multiselect .multiselect__tags{
  max-height: 50px;
  overflow: hidden;
  padding: 15px 18px 0 40px;
}
.filter-Wrap1 .multiselect__option--highlight {
    background: #5897fb;
}
.filter-Wrap1 .multiselect__tag{
  margin-right: 1px;
  margin-bottom: 1px;
  display: inline-flex;
}
.filter-Wrap1 .multiselect__content li:first-child{
  margin-bottom: 1.5rem;
  border-bottom: 1px solid;
}
.filter-Wrap1 .multiselect__content li:first-child>span{
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.filter-Wrap1 .multiselect__content-wrapper .close-button{
  position: sticky;
  top: 10px;
  text-align: left;
  z-index: 100;
  height: 0;
}
</style>
<style scoped>
.multiselect__single {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.checkbox-label {
  display: block;
  margin-right: 20px;
}
.test {
  position: absolute;
  right: 1vw;
}
</style>
